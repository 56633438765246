import React from 'react';

const NotFound = () => {
  return (
    <React.Fragment>
      <h1>Not found</h1>
      <p>Not found</p>
    </React.Fragment>
  );
};

export default NotFound;
