import React from 'react';

const Home = () => {
  return (
    <React.Fragment>
      <h1>Home</h1>
      <p>Main route</p>
    </React.Fragment>
  );
};

export default Home;
