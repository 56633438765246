import React from 'react';

const Page2 = () => {
  return (
    <React.Fragment>
      <h1>Page 2</h1>
      <p>Page 2</p>
    </React.Fragment>
  );
};

export default Page2;
