import React from 'react';

const Page3 = () => {
  return (
    <React.Fragment>
      <h1>Page 3</h1>
      <p>Page 3</p>
    </React.Fragment>
  );
};

export default Page3;
